<template>
  <body-with-header class="h-100" title="درخواست همکاری" :ads="true">
    <CRow class="w-100">
        <button></button>
    </CRow>
    <CRow class="w-100">
    </CRow>
  </body-with-header>
</template>
  
  <script>
import { mapActions } from "vuex";
import BodyWithHeader from "../../components/bodyWithHeader.vue";

export default {
  name: "cooperationRequestForm",
  components: {
    BodyWithHeader,
  },
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات...",
    };
  },
  watch: {},
  methods: {
    // ...mapActions("dietPlan", ["GetAllDietPlanUserFarms"]),
  },
  mounted() {},
};
</script>
  
  <style>
.main-card-finder {
  display: flex;
  width: 100%;
  height: 65vh;
  flex-wrap: wrap;
  flex-direction: column;
}
</style>